import React from "react"

import {Layout, Hero} from "components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {

    if (typeof window !== 'undefined' && window.location.href.indexOf('/knowledge-base/') > -1) {
        window.location.href = 'https://support.simplebackups.com/en/collections/1334888-frequently-asked-questions'
        return null
    } else if (typeof window !== 'undefined' && window.location.href.indexOf('/blog/category') > -1) {
        window.location.href = '/blog/?_from=' + encodeURIComponent(window.location.href)
        return null
    }

    return (
      <Layout>
        <SEO title="404: Not found" />
        <center>
            <h1>NOT FOUND</h1>
            <p>You just took a route that leads nowhere... Return back <a href="/?ref=404">home</a>?</p>
        </center>
      </Layout>
    )
}

export default NotFoundPage
